body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Alegreya Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("static/images/bg.png");
  background-attachment: fixed;
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav#blog-nav div.nextui-c-egxOLa {
  max-width: 100% !important;
  background: #fff !important;
  color: #000 !important;
}

nav#top-nav div.nextui-c-egxOLa {
  max-width: 100% !important;
  background: #000 !important;
  color: #fff !important;
}

.active.navs-active {
  opacity: 1;
}

.navs-active:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 17%;
  height: 7px;
  width: 7px;
  background: #fff;
  border-radius: 50%;
  opacity: 0;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.navs-active:after {
  content: attr(data-hover);
  position: absolute;
  left: 50%;
  top: 70%;
  opacity: 0;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.navs:hover {
  color: #eee !important;
}

.hero-container {
  height: 100%;
  position: relative;
}

.hero-container-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.hero {
  width: 100%;
  position: static;
  height: 150%;
  object-fit: cover;
  /* object-position: 40% 0; */
  /* margin-top: -18rem; */
}

.bg {
  /* height: 25rem; */
  position: relative;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(45deg, rgb(199, 199, 199), rgb(0, 0, 0)) border-box;
  border: 0.2rem solid transparent;
  border-radius: 5%;
}

.contact-link:hover {
  font-weight: 600;
  color: #000;
}

.icon-link {
  transition: 0.5s;
}

.icon-link:hover {
  transform: translateY(-10px);
  color: #fff;
}

.cv-button {
  background-color: #141414 !important;
  border: 1px solid #141414 !important;
}

.cv-button:hover {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #141414 !important;
  border: 1px solid #141414;
}

.contact-button {
  border-color: #141414 !important;
  color: #141414 !important;
}

.contact-button:hover {
  background-color: #141414 !important;
  color: #fff !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.about-image {
  border-radius: 5%;
}

.about-container-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-left: -1rem;
  margin-top: -1rem;
  /* border-radius: 3rem; */
}

#top-nav .nextui-c-gpbAMl .line {
  background: #fff !important;
}

#top-nav .nextui-c-kzikkm:hover {
  color: rgba(255, 255, 255, 0.8) !important;
  opacity: 1 !important;
}

#blog-nav .nextui-c-kzikkm:hover {
  color: rgba(0, 0, 0, 0.8) !important;
  opacity: 1 !important;
}

.active-nav {
  opacity: 1 !important;
}

.change-hover {
  /* opacity: 0; */
  transition: opacity 1s;
}

.h-50 .link-active {
  font-weight: 700 !important;
}

div#homeL,
div#aboutL,
div#proL,
div#contactL {
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  opacity: 0;
  transition: opacity 1s;
}

div#postsL,
div#newPostL,
div#loginL,
div#registerL,
div#newProjectL,
div#newMailL {
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 50%;
  position: relative;
  opacity: 0;
  transition: opacity 1s;
}

.css-oyyiy1-MuiFormLabel-root-MuiInputLabel-root,
.css-gvfa6g-MuiFormLabel-root-MuiInputLabel-root,
.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.css-66dh3a-MuiInputBase-input-MuiInput-input,
.nextui-c-hzQjrs,
.nextui-c-jeuecp,
.MuiInputBase-input,
.MuiFormLabel-root {
  font-size: 1.5rem !important;
}

.css-gvfa6g-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.MuiInputLabel-root.Mui-focused {
  color: rgb(0, 0, 0) !important;
  font-weight: 600 !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.MuiInput-input {
  margin-top: 1rem !important;
}

.css-isv2bf-MuiInputBase-root-MuiInput-root:after,
.css-sra7id-MuiInputBase-root-MuiInput-root:after,
.MuiInput-root:after {
  border-bottom: 2px solid #000 !important;
}

.overlay {
  position: absolute !important;
  background: rgb(255, 255, 255, 0.9) !important;
  inset: 0 !important;
  opacity: 0 !important;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.overlay:hover {
  opacity: 1 !important;
  z-index: 3;
}

.nextui-c-PJLV-iglJuSn-css,
.nextui-c-PJLV-icuXedL-css {
  /*text-align: center;*/
}

.nextui-c-PJLV-icuXedL-css {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.nextui-c-PJLV-icuXedL-css:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.container {
  max-width: 90% !important;
}

.nextui-c-gEEnVX-qXFXw-color-default .nextui-avatar-bg {
  background: #000 !important;
}

.nextui-c-gEEnVX-iHugVF-size-md .nextui-avatar-text {
  font-weight: 600 !important;
}

.fr-no-borders thead tr th,
.fr-no-borders tbody tr td {
  border-style: none;
}

.fr-blue {
  color: #4280f7;
}

button.MuiLink-button {
  color: #000 !important;
}

button.MuiLink-button:hover {
  font-weight: 700 !important;
}

/*Post body styles*/

div#post-body ul {
  list-style-type: disc !important;
}

div#post-body table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}

div#post-body thead tr {
  overflow: visible;
}

div#post-body thead tr th:first-child {
  padding-left: var(--nextui-space-8);
  border-top-left-radius: var(--nextui-radii-md);
  border-bottom-left-radius: var(--nextui-radii-md);
}

div#post-body thead tr th {
  height: var(--nextui-space-14);
  cursor: default;
  background: var(--nextui-colors-accents0);
  color: var(--nextui-colors-accents7);
  font-size: var(--nextui-fontSizes-sm);
  text-align: left;
  position: relative;
  outline: none;
}

div#post-body thead tr th:last-child {
  padding-right: var(--nextui-space-8);
  border-top-right-radius: var(--nextui-radii-md);
  border-bottom-right-radius: var(--nextui-radii-md);
}

div#post-body tbody {
  display: table-row-group;
}

div#post-body tbody tr {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0px;
}

div#post-body tbody tr th {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
}

div#post-body tbody tr td {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: left;
  padding: 16px 16px 16px 0;
  color: rgba(0, 0, 0, 0.87);
  flex-direction: row-reverse;
}

div#post-body tbody tr td:first-child {
  padding-left: var(--nextui-space-8);
}

div#post-body tbody tr td:last-child {
  padding-right: var(--nextui-space-8);
}

/*Footer*/
.footer {
  position: relative;
  width: 100%;
  background: #000;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.social-icon__link {
  font-size: 3rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}

.post-social-icon__link {
  font-size: 3rem;
  margin: 0 0.5rem;
  display: inline-block;
  transition: 0.5s;
}

.social-icon__link:hover {
  transform: translateY(-10px);
  color: #fff;
}

.post-social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
  color: #fff;
}

.footer p {
  color: #fff;
  font-family: "Alegreya Sans", sans-serif !important;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 400;
}

.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("./static/images/black wave.png");
  background-size: 1000px 100px;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 4s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 3s linear infinite;
}

.footer .nextui-input-content--left,
.footer .nextui-c-ctQnhE svg,
.footer .nextui-c-hzQjrs-hyKnjc-asPlaceholder-true,
.footer .nextui-c-jeuecp {
  color: #fff !important;
}

.footer .nextui-c-iwmZdB,
.footer .nextui-c-jeuecp {
  --nextui--inputBorderColor: #fff !important;
  --nextui--inputHoverBorderColor: rgb(199, 199, 199) !important;
}

.footer .subscribe-button-bg {
  background-color: transparent !important;
  transition: 0.5s;
}

.footer .subscribe-button {
  font-size: 24px;
  color: #fff;
  opacity: 0.8;
}

.footer .subscribe-button:hover {
  font-size: 30px;
  color: #fff;
  opacity: 1;
}

.footer .subscribe-button-bg:hover {
  transform: translateX(10px);
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }

  100% {
    background-position-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }

  100% {
    background-position-x: 0px;
  }
}

div.card-width {
    width: 40% !important;
  }

@media (max-width: 599px) {
  .title-mt {
    margin-top: 2rem !important;
  }

  div.card-width {
    width: 95% !important;
  }

  .social-icon__link {
    font-size: 2rem;
  }
}

@media (max-width: 800px) {
  .nextui-c-PJLV-icuXedL-css {
    font-size: 1rem !important;
  }

  .nextui-c-PJLV-iglJuSn-css {
    font-size: 0.8rem !important;
  }

  .t-center {
    text-align: center !important;
  }
}

@media (max-width: 1000px) {
  .hero-text {
    position: absolute;
    text-align: center;
  }

  .hero-container {
    opacity: 0.3;
  }

  ul.social-icon {
    justify-content: center !important;
  }

  .overlay {
    position: absolute !important;
    background: rgb(255, 255, 255, 0.9) !important;
    /*inset: 0 !important;*/
    top: 50% !important;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1 !important;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  .nextui-c-PJLV-icuXedL-css {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 1000px) {
  div.card-width {
    width: 70% !important;
  }
}

@media (max-width: 600px) {
  .f-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 570px) {
  .email-mt {
    margin-top: 2.22rem !important;
  }
}

@media (max-width: 570px) {
  .field-mt {
    margin-top: 1rem !important;
  }

  .nextui-c-PJLV-icuXedL-css {
    font-size: 0.8rem !important;
  }

  .nextui-c-PJLV-iglJuSn-css {
    font-size: 0.5rem !important;
  }

  .et-signage {
    font-size: 1rem !important;
  }

  h5.logIn-signUp {
    flex-direction: column !important;
  }
}

@media (max-width: 767px) {
  .f-reverse-2 {
    flex-direction: column-reverse;
    text-align: center;
  }

  .r-width {
    width: 80% !important;
  }

  .j-center {
    justify-content: center;
  }
}
